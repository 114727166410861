import { useMediaQuery, Popover, Button, alpha } from "@mui/material";
import { Stack } from "@mui/system";
import { fDate } from "../../../fe-ui/utils/formatTime";
import { useTheme } from "@mui/system";

import { useRecoilValue } from "recoil";
import { dateRangeAtom } from "../../../state/search-state";
import { Props } from "./Props";

import { FormSearchSubmitButton } from "./FormSearchSubmitButton";
import { useTranslation } from "../../../fe-ui/hooks/use-translation";
import { SearchGuestsInputForSearch } from "../../widgets/SearchGuestsInput/SearchGuestsInputForSearch";
import { Popover as CustomPopover, usePopover } from "../../../fe-ui/hooks/use-popover";
import { FormSearchDatePickerV2 } from "./widgets/FormSearchDatePickerV2";
import FormSearchCityPicker from "./FormSearchCityPicker";
import { ThemeMode } from "../../../fe-ui/theme/palette";
import { useEmbeddedContext } from "../../EmbeddedContext";

const FormSearchCompactPopover = ({
  popover,
  ...props
}: {
  popover: CustomPopover;
} & Props) => {
  const { cityIdContext } = props;

  return (
    <Popover
      keepMounted={false}
      id="form-search-compact-popover"
      open={Boolean(popover.open)}
      onClose={popover.onClose}
      anchorEl={popover.open}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Stack direction={"column"} spacing={3} sx={{ p: 2 }}>
        {!cityIdContext && <FormSearchCityPicker isCompact cities={undefined} />}

        {/* {!hotelIdContext && <FormSearchHotelPicker hotels={props.hotels} />} */}

        <FormSearchDatePickerV2 context="search" />
        <SearchGuestsInputForSearch context="search" size="medium" />
        <FormSearchSubmitButton navigateToSearchPage isCompact onClick={popover.onClose} />
      </Stack>
    </Popover>
  );
};

const FormSearchCompact = (props: Props) => {
  const { cityIdContext, hotelIdContext } = props;
  const popover = usePopover();
  const t = useTranslation();

  const theme = useTheme();
  const { themeMode } = useEmbeddedContext();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  const [from, to] = useRecoilValue(dateRangeAtom);

  if (isXS) {
    return (
      <>
        <Button
          // fullWidth={fullWidth}
          onClick={popover.onOpen}
          aria-owns={popover.open ? "form-search-compact-popover" : undefined}
          aria-haspopup="true"
          variant="contained"
          color="inherit"
          sx={{
            whiteSpace: "nowrap",
            px: 2,
            borderRadius: themeMode === ThemeMode.aztravel ? "22px" : undefined,
          }}
        >
          {from && to ? (
            <>
              {fDate(from, "DD.MM")}–{fDate(to, "DD.MM")}
            </>
          ) : (
            <>{t("search_params")}</>
          )}
        </Button>

        {popover.open && <FormSearchCompactPopover popover={popover} {...props} />}
      </>
    );
  }

  return (
    <Stack
      sx={{
        backgroundColor: "white",
        borderRadius: "16px",
        boxShadow: props.hasShadow ? `0px 1px 2px 0px ${alpha(theme.palette.grey[500], 0.16)}` : undefined,
        whiteSpace: "nowrap",
      }}
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {!cityIdContext && !hotelIdContext && <FormSearchCityPicker isCompact />}
      {/* {!hotelIdContext && <FormSearchHotelPicker hotels={props.hotels} size="small" />} */}
      <FormSearchDatePickerV2 isCompact context="search" size="medium" dateFormat="default" />
      <SearchGuestsInputForSearch isCompact context="search" size="medium" />
      <FormSearchSubmitButton isCompact />
    </Stack>
  );
};

export default FormSearchCompact;
